
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, _createElement('div', { 'className': 'cm_FacetPanel' }, [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeDollarInchMm1_rangeChip() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, '\n    ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  ') : null, this.toPosInf ? _createElement(_Fragment, { 'key': '626' }, ' ', dollar, this.selection[0], inch, mm, ' and more ') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '732' }, ' ', dollar, this.selection[1], inch, mm, ' and less ') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '8680'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '4900'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', {
                        'className': 'facetbody',
                        'role': 'list'
                    }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_chips' },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn button cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.Facets(function () {
            return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, !this.isExtraRange ? _createElement('span', { 'key': '217' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '278' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '394' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '497' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                                'className': 'facetentryval',
                                                'key': '609'
                                            }, this.hitCount) : null);
                                        }, { count: undefined })];
                                }
                                function scopeDollarInchMm4() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '12082'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button',
                                                'data-cm-role': 'add-facet'
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', { 'className': 'cm_icon icon' }, _createElement('use', { 'xlinkHref': '#icon-add' }))] : null, !this.isCollapsed ? [_createElement('svg', { 'className': 'cm_icon icon' }, _createElement('use', { 'xlinkHref': '#icon-remove' }))] : null), _createElement('div', {
                                    'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '597'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '683'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input  cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1003'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '11721'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '3003'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'tabIndex': '0'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_selectFacet',
                                    'key': '3329'
                                }, [this.select(function () {
                                        function repeatEntry1(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry2(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry3(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry4(entry, index) {
                                            return _createElement('div', {
                                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                'key': entry.value,
                                                'onClick': () => this.onChange(entry.term)
                                            }, this.showCheckboxes ? _createElement('input', {
                                                'type': 'checkbox',
                                                'readOnly': true,
                                                'checked': entry.selected,
                                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                'key': '3529'
                                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                'className': 'cm_dropdown_hitCount',
                                                'key': '3731'
                                            }, entry.hitCount) : null);
                                        }
                                        return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                            'key': '62'
                                        }, _createElement('select', {
                                            'className': 'cm_select_inner-select cm_select__pretty',
                                            'onChange': this.onChange,
                                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                            'disabled': this.disabled
                                        }, this.useNativeDropdown ? [
                                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n          ', '', this.title, '\n        ') : null,
                                            this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '7771'
                                                }),
                                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '7774'
                                                }, 'Additional Makes'),
                                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                            ] : null,
                                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                                            'key': '_current',
                                            'value': '_current'
                                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                'key': '2851'
                                            },
                                            !this.hideNullOption ? _createElement('div', {
                                                'className': 'option',
                                                'key': 'null-option',
                                                'onClick': () => this.onChange('')
                                            }, '\n      ', '', this.title, '\n    ') : null,
                                            this.loading ? _createElement('div', {
                                                'className': 'option',
                                                'key': 'null-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                        ]) : null));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '9213'
                                }, !this.ranges.length ? [
                                    _createElement('div', { 'key': '92971' }, '$', this.selection[0], ' to $', this.selection[1]),
                                    '\n  ',
                                    this.slider,
                                    '\n  ',
                                    _createElement('div', {
                                        'className': 'cm_flex',
                                        'key': '92973'
                                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                ] : null, this.ranges.length ? [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cmRepeater_Values',
                                            'key': '95651'
                                        },
                                        _map(this.Values, repeatValues3.bind(this))
                                    ])] : null, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min price',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max price',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'type': 'button',
                                            'className': 'cm_btn button',
                                            'data-cm-role': 'add-facet'
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_reviewFacet',
                                    'key': '13971'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues6.bind(this))
                                ])) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'search' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.hasMessage('VehicleCategoryIncompatibleVehicle') ? [_createElement('div', { 'key': '40860' }, _createElement('div', {}, '\n    These parts will not fit the selected vehicle.\n    ', _createElement('a', {
                    'href': '/search',
                    'tabIndex': '0',
                    'className': 'cm_message-link'
                }, '\n      Click here to view parts that fit this vehicle.\n    ')))] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, this.vehicleString)) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarWheels(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3616'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3818'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '149'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8641'
                                    }),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8644'
                                    }, 'Additional Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2938'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = 'Shop Wheels';
                return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection
                }, '\n            Clear\n          ')))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarTires(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3616'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3818'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '149'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8641'
                                    }),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8644'
                                    }, 'Additional Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2938'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = 'Shop Tires';
                return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection
                }, '\n            Clear\n          ')))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'actionBar cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button button--primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '680'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8090'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_per-page actionBar-section' }, _createElement('span', { 'className': 'form-label' }, 'Per Page'), '\n  ', this.pageSizeSelect, '\n'), [_createElement('div', {
                    'className': 'cm_view-toggle ' + this.view,
                    'key': '23020'
                }, _createElement('div', { 'className': 'cm_view-toggle_btn' }, _createElement('a', {
                    'onClick': this.setView('list-view'),
                    'className': 'list-view-btn',
                    'title': 'List View'
                }), _createElement('a', {
                    'onClick': this.setView('grid-view'),
                    'className': 'grid-view-btn',
                    'title': 'Grid View'
                })))], [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label form-label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onClick': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_sort actionBar-section sort-by' }, _createElement('span', { 'className': 'form-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function repeatI2(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, this.on_sale && !this.product_promo ? _createElement('div', {
                            'className': 'sale-flag-side',
                            'key': '85'
                        }) : null, this.custom_badge && !this.product_promo ? _createElement('div', {
                            'className': 'sale-flag-side sale-flag-side--' + this.custom_badge.toLowerCase(),
                            'key': '173'
                        }) : null, this.product_promo ? _createElement('div', {
                            'className': 'sale-flag-side sale-flag-side--coupon',
                            'key': '316'
                        }) : null, _createElement('a', { 'href': this.custom_url }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.images_image_thumbnail1)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'className': 'card-image lazyautosizes lazyloaded'
                        }), !!this.images_image_thumbnail2 ? _createElement('img', {
                            'src': this.resizeImage(this.images_image_thumbnail2),
                            'alt': this.removeHTML(this.title),
                            'title': this.removeHTML(this.title),
                            'className': 'card-image lazyautosizes lazyloaded',
                            'key': '685'
                        }) : null)), _createElement('figcaption', { 'className': 'card-figcaption' }, _createElement('div', { 'className': 'card-figcaption-body' }, _createElement('div', { 'className': 'card-buttons' }, _createElement('button', {
                            'type': 'button',
                            'className': 'button button--small card-figcaption-button card-figcaption-button--compare',
                            'data-compare-id': this.id,
                            'data-compare-title': this.name
                        }, '\n              Compare\n            ')), _createElement('div', { 'className': 'card-buttons card-buttons--alt' }, _createElement('a', {
                            'className': 'button button--small card-figcaption-button quickview',
                            'data-product-id': this.id
                        }, '\n              Quick view\n            '))))), _createElement('div', { 'className': 'card-body' }, _createElement('div', { 'className': 'card-title' }, _createElement('a', Object.assign({}, {
                            'href': this.custom_url,
                            'data-instantload': true
                        }, { dangerouslySetInnerHTML: { __html: this.name } })), _createElement('a', {
                            'href': this.custom_url,
                            'className': 'small-model-desc',
                            'data-instantload': true
                        }, this.vehicle_info)), _createElement('p', mergeProps({
                            'className': 'card-text card-text--brand',
                            'data-test-info-type': 'brandName'
                        }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('div', { 'className': 'stamped-product-reviews-badge' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cm_review-stars' },
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI2.bind(this)),
                            this.review_count ? _createElement('span', { 'key': '2328' }, '(', this.review_count, ')') : null
                        ])), _createElement('div', {
                            'className': 'card-text card-text--price',
                            'data-test-info-type': 'price'
                        }, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, this.retail_price && this.retail_price > this.price ? _createElement('span', {
                            'className': 'price-label',
                            'key': '2550'
                        }, '\n            Our Price:\n          ') : null, this.on_sale ? _createElement('span', {
                            'className': 'price-now-label',
                            'key': '2699'
                        }, 'Now:') : null, _createElement('span', {
                            'data-product-price-without-tax': true,
                            'className': 'price price--withoutTax price--main'
                        }, '\n            ', this.formatPrice(this.price), '\n          ')), this.on_sale ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '2942'
                        }, _createElement('span', { 'className': 'price-was-label' }, 'Was:'), _createElement('span', {
                            'data-product-rrp-price-without-tax': true,
                            'className': 'price price--rrp'
                        }, '\n            ', this.formatPrice(this.regular_price), '\n          ')) : null, !this.on_sale && this.retail_price && this.retail_price > this.price ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '3264'
                        }, _createElement('span', { 'className': 'price-was-label' }, 'MSRP:'), _createElement('span', {
                            'data-product-rrp-price-without-tax': true,
                            'className': 'price price--rrp'
                        }, '\n            ', this.formatPrice(this.retail_price), '\n          ')) : null)), !this.out_of_stock ? _createElement('div', { 'key': '3680' }, !this.has_options ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'className': 'product-cart-buton',
                            'key': '3719'
                        }, '\n        Add to Cart\n      ') : null, this.has_options ? _createElement('a', {
                            'href': this.custom_url,
                            'className': 'product-cart-buton',
                            'key': '3866'
                        }, 'View Product') : null) : null));
                    }, { count: undefined })];
            }
            function repeatItems2(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function repeatI2(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        return _createElement('li', { 'className': 'product-list-item' }, _createElement('div', { 'className': 'product-image card' }, _createElement('a', { 'href': this.custom_url }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.images_image_thumbnail1)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'className': 'card-image lazyautosizes lazyloaded'
                        }), !!this.images_image_thumbnail2 ? _createElement('img', {
                            'src': this.resizeImage(this.images_image_thumbnail2),
                            'alt': this.removeHTML(this.title),
                            'title': this.removeHTML(this.title),
                            'className': 'card-image lazyautosizes lazyloaded',
                            'key': '347'
                        }) : null)), this.on_sale && !this.product_promo ? _createElement('div', {
                            'className': 'sale-flag-side',
                            'key': '592'
                        }) : null, this.custom_badge && !this.product_promo ? _createElement('div', {
                            'className': 'sale-flag-side sale-flag-side--' + this.custom_badge.toLowerCase(),
                            'key': '678'
                        }) : null, this.product_promo ? _createElement('div', {
                            'className': 'sale-flag-side sale-flag-side--coupon',
                            'key': '819'
                        }) : null), _createElement('div', { 'className': 'product-info' }, _createElement('div', { 'className': 'product-info__left card-title' }, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'product-info_link'
                        }, _createElement('span', mergeProps({ 'className': 'product-info_title' }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', { 'className': 'product_meta' }, _createElement('div', {
                            'className': 'product_sku',
                            'data-fitment': this.Fitment
                        }, _createElement('span', {}, '# ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('span', {}, 'MPN: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.mpn } })))), _createElement('a', {
                            'href': this.custom_url,
                            'className': 'product_descr small-model-desc'
                        }, _createElement('div', { 'className': 'product_descr-text' }, this.vehicle_info)), _createElement('div', { 'className': 'stamped-product-reviews-badge' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cm_review-stars' },
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI2.bind(this)),
                            this.review_count ? _createElement('span', { 'key': '1948' }, '(', this.review_count, ')') : null
                        ]))), _createElement('div', { 'className': 'product-info__right' }, _createElement('div', { 'className': 'product_form-holder' }, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, this.retail_price && this.retail_price > this.price ? _createElement('span', {
                            'className': 'price-label',
                            'key': '2184'
                        }, '\n            Our Price:\n          ') : null, this.on_sale ? _createElement('span', {
                            'className': 'price-now-label',
                            'key': '2333'
                        }, 'Now:') : null, _createElement('span', {
                            'data-product-price-without-tax': true,
                            'className': 'price price--withoutTax price--main'
                        }, '\n            ', this.formatPrice(this.price), '\n          ')), this.on_sale ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '2576'
                        }, _createElement('span', { 'className': 'price-was-label' }, 'Was:'), _createElement('span', {
                            'data-product-rrp-price-without-tax': true,
                            'className': 'price price--rrp'
                        }, '\n            ', this.formatPrice(this.regular_price), '\n          ')) : null, !this.on_sale && this.retail_price && this.retail_price > this.price ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '2898'
                        }, _createElement('span', { 'className': 'price-was-label' }, 'MSRP:'), _createElement('span', {
                            'data-product-rrp-price-without-tax': true,
                            'className': 'price price--rrp'
                        }, '\n            ', this.formatPrice(this.retail_price), '\n          ')) : null), !this.out_of_stock ? _createElement('div', {
                            'className': 'product-cart',
                            'key': '3305'
                        }, !this.has_options ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'className': 'product-cart-buton',
                            'key': '3367'
                        }, '\n          Add to Cart\n        ') : null, this.has_options ? _createElement('a', {
                            'href': this.custom_url,
                            'className': 'product-cart-buton',
                            'key': '3520'
                        }, 'View Product') : null) : null)));
                    }, { count: undefined })];
            }
            return window.Convermax.showConvermaxResults ? _createElement('div', {
                'className': 'cm_SearchResult',
                'key': '0'
            }, this.view === 'grid-view' ? _createElement.apply(this, [
                'div',
                {
                    'className': 'product-' + this.view + (this.view === 'grid-view' ? ' productGrid' : '') + ' cmRepeater_items',
                    'key': '80'
                },
                _map(this.items, repeatItems1.bind(this))
            ]) : null, this.view === 'list-view' ? _createElement.apply(this, [
                'div',
                {
                    'className': 'product-' + this.view + (this.view === 'grid-view' ? ' productGrid' : '') + ' cmRepeater_items',
                    'key': '7556'
                },
                _map(this.items, repeatItems2.bind(this))
            ]) : null) : null;
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.SearchHeader(function () {
            return window.Convermax.showConvermaxResults ? _createElement('div', {
                'className': 'pagination cm_SearchHeader',
                'key': '0'
            }, _createElement('div', { 'className': 'pagination-info' }, 'Items ', this.pageFrom, '-', this.pageTo, ' of ', this.totalHits, ' total'), [this.pagination(function () {
                    function repeatButtons1(buttons, buttonsIndex) {
                        return [buttons(function () {
                                return this.template === 'prev' ? _createElement('li', { 'className': 'pagination-item pagination-item--previous cmTemplate_prev' }, _createElement('a', { 'className': 'pagination-link' }, _createElement('i', {
                                    'className': 'icon',
                                    'aria-hidden': 'true'
                                }, _createElement('svg', {}, _createElement('use', {
                                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                    'xlinkHref': '#icon-chevron-left'
                                }))), '\n      Previous\n    ')) : this.template === 'next' ? _createElement('li', { 'className': 'pagination-item pagination-item--next cmTemplate_next' }, _createElement('a', { 'className': 'pagination-link' }, '\n      Next\n      ', _createElement('i', {
                                    'className': 'icon',
                                    'aria-hidden': 'true'
                                }, _createElement('svg', {}, _createElement('use', {
                                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                    'xlinkHref': '#icon-chevron-right'
                                }))))) : this.template === 'delimiter' ? _createElement('li', { 'className': 'pagination-item cmTemplate_delimiter' }, _createElement('span', {}, '\u2026')) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, _createElement('a', { 'className': 'pagination-link' }, this.page)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_pagination' }, _createElement.apply(this, [
                        'ul',
                        { 'className': 'pagination-list cmRepeater_buttons' },
                        _map(this.buttons, repeatButtons1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:FilterChips","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:SearchVehicleWidget","cm:filterInput","cm:FacetBarWheels","cm:filterInput","cm:FacetBarTires","cm:dialogButton","cm:facetDialogButton","cm:facetToggle","cm:SearchHeader","cm:SearchResult","cm:pagination","cm:SearchHeader"]